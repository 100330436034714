import { graphql } from "gatsby";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import * as React from "react";
import styled from "styled-components";
import CompanyProfileCard from "../components/CompanyProfileCard";
import ContactCard from "../components/ContactCard";
import Seo from "../components/Seo";
import Title from "../components/Title";
import BCContainer from "../styles/BCContainer";
import ContainerStyle from "../styles/ContainerStyle";

const AboutPageStyle = styled.section`
  h2 {
    margin-bottom: 50px;
  }

  @media screen and (min-width: ${({ theme: { breakpoint } }) =>
      breakpoint.md}) {
    ul {
      &:nth-child(odd) {
        direction: ltr;
      }
    }
  }
`;

export default function AboutPage({
  data: {
    about: { nodes },
  },
}) {
  const { title, contactInfo, companyProfileTitle, companyProfile, gmap } =
    nodes[0];
  const location = typeof window !== "undefined" ? window.location : "";
  return (
    <ContainerStyle>
      <AboutPageStyle>
        <BCContainer>
          <ContainerStyle>
            <Seo
              seo={{
                title: "About IconicTaste",
                metaTitle: "about iconic taste",
                metaDescription:
                  "We are dedicated to lead with our heart, mind and soul. To passionately inspire every customer, every employee and every partner, by delivering trusted support products that make a difference worldwide.",
                sharedImage: "",
                keywords: "iconictasteusa, iconic taste usa",
              }}
            >
              <Breadcrumb
                location={location}
                crumbLabel="About us"
                crumbSeparator="/"
              />
            </Seo>
          </ContainerStyle>
        </BCContainer>
        <Title title={companyProfileTitle} scrollerId="company-profile" />
        <ul>
          {companyProfile.map(item => (
            <CompanyProfileCard key={item.id} {...item} />
          ))}
        </ul>
        <Title title={title} scrollerId="contact-us" />
        <ContactCard map={gmap} info={contactInfo} />
      </AboutPageStyle>
    </ContainerStyle>
  );
}

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    about: allStrapiAbout {
      nodes {
        title
        contactInfo {
          id
          text
          title
        }
        companyProfileTitle
        companyProfile {
          id
          description {
            data {
              description
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300, placeholder: BLURRED, formats: AUTO)
              }
            }
          }
          title
        }
        gmap
      }
    }
  }
`;
