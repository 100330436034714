import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { px } from '../utils';

const CompanyProfileCardStyle = styled.li`
  margin-bottom: ${px(20)};
  text-align: center;
  .details {
    margin-top: 20px;
    direction: ltr;
    h2 {
      color: ${({ theme: {palette} }) => palette.secondary.main};
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
    }
  }

  @media screen and (min-width:${({ theme: {breakpoint}}) => breakpoint.md}){
    display: grid;
    grid-template-columns: 300px 1fr;
    align-items: center;
    gap: 50px;
    text-align: left;
    &:nth-child(even) {
      direction: rtl;
    }
    [data-gatsby-image-wrapper]  {
      grid-column: 1;
    }
    .details { 
      grid-column: 2;
    }
  }
`;

export default function CompanyProfileCard({ description, image, title }) {
  const imageGetter = getImage(image.localFile);
  return(<CompanyProfileCardStyle>
    <GatsbyImage image={imageGetter} alt={image.alternativeText}/>
    <div className="details">
      <h2>{title}</h2>
      <p>
      {description.data.description}
      </p>
    </div>
  </CompanyProfileCardStyle>)
}

