import React from 'react';
import styled from 'styled-components';

const ContactCardStyle = styled.section`
display: flex;
flex-direction: column;
gap: 20px;
  .gmap {
    iframe {
      width:100%
    }
  }

  margin-bottom: 50px;

  @media screen and (min-width: ${({ theme: { breakpoint }}) => breakpoint.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;


const ContactInfoCard = styled.li`
  color: ${({ theme: { palette } }) => palette.primary[900]};
  h4 {
    margin: 0 0 10px 0;
  }
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`


export default function ContactCard({map, info}) {
  return(
    <ContactCardStyle>
      <div className='gmap' dangerouslySetInnerHTML={{ __html: map }} />
      <ul className="details">
      {info.map(({ title, text, id}) => {
        return(
          <ContactInfoCard key={id}>
              <h4 className='title'>{title}</h4>
              <span className='title'>{text}</span>
          </ContactInfoCard>
        )
      })}
      </ul>
    </ContactCardStyle>
  )
}