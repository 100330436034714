import React from "react";
import styled from "styled-components";
import { pxToRem } from "../utils";

const HeadingStyle = styled.div`
  text-align: center;
  h2 {
    font-size: ${({ theme: { typography } }) => pxToRem(typography.h2.md)};
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      display: block;
      background-color: ${({ theme: { palette } }) => palette.secondary.main};
    }
  }
`;

export default function Title({ scrollerId, title }) {
  const { length } = title.split(" ");
  return (
    <HeadingStyle>
      <h2 id={scrollerId}>
        {title.split(" ").map((word, i) =>
          i === length - 1 ? (
            <span key={i} className="highlight">
              {" " + word}
            </span>
          ) : (
            " " + word
          )
        )}
      </h2>
    </HeadingStyle>
  );
}
