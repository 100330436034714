import styled from "styled-components";

const BCContainer = styled.div`
  background-color: ${({ theme: { palette } }) => palette.primary[100]};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 30px;
  .breadcrumb__list {
    display: flex;
    padding: 10px 0;
    margin: 0;
    flex-wrap: wrap;
    line-height: 25px;
    .breadcrumb__separator {
      padding: 0 5px;
    }
    .breadcrumb__link {
      color: black;
      text-transform: capitalize;
    }
    & .breadcrumb__link:last-child {
      color: grey;
      cursor: unset;
    }
  }
`;

export default BCContainer;
